<template>
  <div class="exhibition">
    <div class="_title">商务合作</div>
    <div class="desc">
      <div class="wrap">
<!--        <p>-->
<!--          <span class="font-weight-bold">参展商布展通知</span>-->
<!--          <span class="_blue font-weight-bold examine"> 点击查看</span>-->
<!--        </p>-->


        <!-- <p>
          <span class="font-weight-bold">会展区平面图:</span>
          <span class="_blue font-weight-bold examine"><a href="https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/plugs/%E5%9C%BA%E5%9C%B0%E5%B8%83%E5%B1%80.pdf">点击查看</a></span>
        </p> -->



<!--        <p class="_red">-->
<!--          备注：参展商如需制作布展资料，可联系大会合作会展公司（热???媒，小丁，????，????@qq.com），相关费用参展商自行支付。-->
<!--        </p>-->
      </div>
      <div class="wrap">
        <p class="indent">
          2023分子植物育种大会将于2023年12月10日-12日在四川成都召开。大会将邀请我国分子植物育种领域顶级学者做大会学术报告，并为广大青年学者搭建不同形式、不同层次的学术交流平台。
        </p>
        <p class="indent">
          大会组委会诚邀国内知名企事业单位赞助协办。参展商将有机会与国内分子育种领域的顶尖科学家和青年学者等直接交流洽谈。参展商的名称或Logo将以多种形式予以宣传，助力公司提升品牌形象、扩大市场份额、加快企事业单位在农业科研领域知名度和影响力的晋级步伐。具体参展内容及相应收益如下：
        </p>
      </div>
      <div class="wrap">
        <div class="desc-title">一、钻石赞助（15万元，限1名）</div>
        <p>1. 列为大会协办单位。</p>
        <p>
          2. 大会主题报告1次（总时长25分钟，具体时间组委会确定，报告内容需经组委会审核）。
        </p>
        <p>3. 8m2展位1个，最优位置；（展位提供桌椅、电源、灯光，大会组委会统一制作安装，如需特装则由赞助商自行支付费用）</p>
        <p>4. 会前时间与茶歇时间播放3分钟宣传片1次，共<u>8</u>次（企业提供）；。</p>
        <p>5. 在大会手册中提供2P彩色插页广告，并在资料袋中放宣传单页2P（内容需审核）。</p>
        <p>6. 可在会场公共走廊区域摆放企业易拉宝广告6个（企业提供）。</p>
        <p>7. 提供5个免费会议注册名额，含会议用餐。</p>
        <p>8. 在大会网站明显位置放置赞助商LOGO。</p>
        <p>9. 会议背板展示公司全称或LOGO。</p>
      </div>
      <div class="wrap">
        <div class="desc-title">二、 铂金赞助（10万元，限2名）</div>
        <p>
          1. 青年论坛报告一次（总时长20分钟，具体时间组委会确定，报告内容需经组委会审核）。
        </p>
        <p>2. 标准展位1个，仅次于钻石赞助优先选择位置；（展位提供桌椅、电源、灯光，大会组委会统一制作安装）。</p>
        <p>3. 会前时间与茶歇时间播放3分钟宣传片1次，共4次；。</p>
        <p>4. 可在会场公共走廊区域摆放企业易拉宝广告4个（企业提供） 。</p>
        <p>5. 在大会手册中提供1P彩色插页广告，并在资料袋中放宣传单页1P（内容需审核）。</p>
        <p>6. 提供4个免费会议注册名额，含会议用餐。</p>
        <p>7. 会议背板展示公司全称及LOGO或在大会网站明显位置放置赞助商LOGO。</p>
      </div>
      <div class="wrap">
        <div class="desc-title">三、金牌赞助（5万元）</div>
        <p>1. 标准展位1个；（展位提供桌椅、电源、灯光，大会组委会统一制作安装）。</p>
        <p>2. 在大会论文集中提供1彩色插页广告。</p>
        <p>3. 提供3个免费会议注册名额，含会议用餐。</p>
        <p>4. 可在会场公共走廊区域摆放企业易拉宝广告2个（企业提供）。</p>
        <p>5. 会议背板展示公司全称及LOGO或在大会网站明显位置放置赞助商LOGO。</p>
      </div>
      <div class="wrap">
        <div class="desc-title">四、会议手册广告</div>
        <p>插页：6000元/页面；封三：10000元。（广告内容需经审核，大会负责印刷。）</p>
      </div>
      <div class="wrap">
        <div class="desc-title">五、代发资料（1.5万元/份）</div>
        <p>
          代发企业宣传资料（入会议资料袋）。一份资料为4页A4纸张以内。资料内容需经审核。
        </p>
      </div>
      <div class="wrap">
        <div class="desc-title">六、其他赞助</div>
        <p>
          大会饮用水：1.5万元（印制赞助商logo）
        </p>
        <p>
        大会手提袋：2万（印制赞助商logo）
        </p>
        <p>
        大会笔记本：2万（笔记本介绍页印制赞助商logo）
        </p>
      </div>
<!--      <div class="wrap">-->
<!--        <div class="desc-title">七、笔记本、签字笔广告</div>-->
<!--        <p>-->
<!--          企业可提供笔记本、中性签字笔，可印有企业标志，数量2000支。-->
<!--        </p>-->
<!--      </div>-->
<!--      <div class="wrap">-->
<!--        <div class="desc-title">八、矿泉水瓶贴纸（2万元，限定1个）</div>-->
<!--        <p>-->
<!--          会议使用瓶装水，印有会议及企业LOGO，企业提供LOGO，由会议组统一设计制作。-->
<!--        </p>-->
<!--      </div>-->
<!--      <div class="wrap">-->
<!--        <div class="desc-title">九、礼品赞助</div>-->
<!--        <p>-->
<!--          企业可提供礼品，可印有企业标志，数量2000套。-->
<!--        </p>-->
<!--      </div>-->
<!--      <div class="wrap">-->
<!--        <div class="desc-title">招商流程：</div>-->
<!--        <p>1. 邮件申请，确认参展项目是否有效。。</p>-->
<!--        <p>2. 确认有效后，参展商在10个工作日内将钱款汇至组委会指定银行账户，否则组委会有权将参展项目提供给其他厂商。</p>-->
<!--      </div>-->
      <div class="wrap">
        <div class="desc-title">联系人：</div>
        <p>王云腾, 电话：18032998601&nbsp;邮箱：wangyunteng@molbreeding.com</p>
        <p>王安琪, 电话：13716078499 &nbsp;邮箱：wanganqi@molbreeding.com</p>
      </div>
<!--      <div class="wrap">-->
<!--        <p>2021年9月6日</p>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style lang="less" scoped>
.exhibition {
  padding: 0 50px;
  padding-bottom: 150px;

  ._title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
  .desc-title {
    font-weight: bold;
    color: #337fe5;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .examine{
      cursor: pointer;
  }
  ._blue {
    color: #337fe5;
  }
  ._red {
    color: #e53333;
  }
  .indent {
    text-indent: 2em;
    line-height: 2em;
  }
  .wrap {
    margin-top: 50px;
    line-height: 20px;
  }
}
</style>
